
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MPrintInvitaion from "@/components/print_invitation/MPrintHeader.vue";
import { LoginInfo } from "@/store";
import { Options, Vue } from "vue-class-component";
import QrcodeVue from "qrcode.vue";
import { State } from "@/store/helper";

type DataType = {
  schoolName: string;
  studentName: string;
  loginId: string;
  password: string;
  schoolId: string;
  code: string;
};

@Options({
  components: {
    MButton,
    MIcon,
    MPrintInvitaion,
    QrcodeVue
  }
})
export default class PrintInvitation extends Vue {
  clipboardStatusType = "clipboard-copy";
  clipboardMessage = "招待文面をコピー";

  @State("selectedLoginInfos") loginInfos!: LoginInfo[];

  SCHOOL_AI_LOGIN_URL = "https://school-ai-app.mingaku.net/login_for_student";
  SCHOOL_AI_RULE_URL = "https://mingaku.net/guide/schoolai1";
  SCHOOL_AI_MANUAL_URL =
    "https://plaid-scallop-d50.notion.site/AI-9584bf67ce314116ac72e45d4cf60fe4";

  get datas(): DataType[] {
    return this.loginInfos.map(info => {
      return {
        schoolName: info.schoolName,
        studentName: info.studentName,
        loginId: info.loginId,
        password: info.password,
        schoolId: info.schoolId,
        code: `${info.loginId} ${info.password} ${info.schoolId}`
      };
    });
  }

  async messageCopy() {
    const message = this.loginInfos
      .map(
        info => `ーーーーーーーー
「${info.studentName}（${info.schoolName}）」さん

”スクールAI”にようこそ！
分からないことがあったら、公式ガイドを確認するか先生に相談しましょう。


▼スクールAI公式ガイド
${this.SCHOOL_AI_MANUAL_URL}


・。・。・。・。・。・。・。・。・。・。・。・。・。・。

■ 「${info.studentName}（${info.schoolName}）」さんのログイン情報
・スクールID：${info.schoolId} 
・ログインID：${info.loginId}
・パスワード：${info.password}
※ログイン時にコピー＆ペーストして使ってください。

■スクールAI
${this.SCHOOL_AI_LOGIN_URL}

・。・。・。・。・。・。・。・。・。・。・。・。・。・。

`
      )
      .join("\n\n\n\n");

    await navigator.clipboard.writeText(message);
    this.clipboardStatusType = "clipboard-check";
    this.clipboardMessage = "コピーしました";
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.clipboardStatusType = "clipboard-copy";
    this.clipboardMessage = "招待文面をコピー";
  }

  print() {
    print();
  }

  shrinkTextToFit(element: HTMLElement): void {
    if (!element) {
      return;
    }
    let fontSize = parseInt(window.getComputedStyle(element).fontSize);
    const minFontSize = 1;
    while (
      element.scrollWidth > element.clientWidth &&
      fontSize > minFontSize
    ) {
      fontSize--;
      element.style.fontSize = `${fontSize}px`;
    }
  }

  created() {
    if (this.datas.length > 0) {
      let title = "スクールAI帳票";
      this.datas.forEach(
        data => (title += `_${data.loginId} ${data.studentName}`)
      );
      document.title = title;
    }
  }

  mounted(): void {
    const containers: HTMLElement[] = Array.from(
      document.querySelectorAll(".textContainer")
    ) as HTMLElement[];

    containers.forEach(c => {
      this.shrinkTextToFit(c);
    });
  }
}
